<template>
    <div>
        <div style="background-color: #ffffff;">
            <div style="background-color: #ffffff;margin: 0 auto;" :style="{width: $oucy.pageWidth+'px'}">
                <div class="pageCenter titleBar" :style="{width: $oucy.pageWidth+'px'}">
                    <div class="titleBar_left">
                        <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="@/assets/logo.png">
                        <div class="titleBar_left_txt">
                            发布中心
                        </div>
                    </div>
                    <div class="titleBar_right" @click="replace('/')">
                        返回首页
                    </div>
                </div>
            </div>
        </div>
        <div class="box">
            <img src="@/assets/images/ReleaseCenter/banner.png" class="banner">
            <div class="centerTitle">
                <div class="textAD">让天下没有难找的家具</div>
                <div class="btn" @click="$oucy.go('/acc/enterprise')">立即入驻</div>
            </div>
        </div>
        <div style="background-color: #ffffff;">
            
        <div class=" boxs" style="margin: 0 auto;" :style="{width: $oucy.pageWidth+'px'}">
            <div class="box pointer" @click="$oucy.go('/ReleaseCenterAskBuySave')">
                <img src="@/assets/images/ReleaseCenter/left.png">
                <div class="title">发布求购</div>
                <div class="explain">求购家具、材料一键发布</div>
            </div>
            <div class="box pointer" @click="goto()">
                <img src="@/assets/images/ReleaseCenter/center.png">
                <div class="title">厂家发布</div>
                <div class="explain">发布产品、相册、视频、VR、新闻</div>
            </div>
            <div class="box pointer">
                <img src="@/assets/images/ReleaseCenter/right.png">
                <div class="title">申请分销</div>
                <div class="explain">加入分销商轻松赚利润</div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import {localSet, localGet, localDel} from "@/store/store"

export default {
    name: "ReleaseCenter",
    components: {
    },
    data() {
        return {}
    },
    methods:{
        goto(){
            let enterprise = localGet('enterprise')||{}
            if(enterprise.id){
                this.$oucy.go('/enter/publish/good')
            }else{
                this.$oucy.confirm('你还没有创建企业，现在要去创建吗？').then(res=>{
                    this.$oucy.go('/acc/enterprise')
                },err=>{})
            }
        },
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.banner {
    width: 100%;
}

.boxs {
    display: flex;
    justify-content: space-between;
    padding: 33px;
}
.box{
    position:relative;
    color: #fff;
}
.title{
    position:absolute;
    left: 44px;
    top:55px;
    font-size: 24px;
    font-weight: bold;
}
.explain{
    position:absolute;
    left: 44px;
    top:95px;
    font-size: 16px;
    font-weight: 400;
}
.centerTitle{
    position:absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    .textAD{
        font-size: 59px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
    }
    .btn{
        width: 256px;
        height: 64px;
        line-height: 64px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 69px;
        background: linear-gradient(90deg, #2090FF, #5FEAFF);
        border-radius: 2px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
    }
}
</style>